import { gql } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import keyBy from 'lodash/keyBy'
import create, { GetState, SetState, State } from 'zustand'
import { KindName } from 'common'
import { client } from '../graphql/client'

export type KindResponse = Kind | null | undefined

interface KindState extends State {
  enabledKinds: Kind[]
  fetch: () => void
  getKindById: (kindId: string) => KindResponse
  getKindByName: (kindName: KindName) => KindResponse
  getKindsEnabledByName: () => { [kindName: string]: Kind }
  getKindsByName: () => { [kindName: string]: Kind }
  isLoadingKinds: boolean
  orderedKinds: Kind[]
  refetch: () => void
  save: (enabledKinds: Kind[], orderedKinds: Kind[]) => void
}

// this is the order kinds should be displayed when shown in
// lists or groups (per MWT)
export const ORDERED_KINDS = [
  KindName.Audiobook,
  KindName.Comic,
  KindName.Ebook,
  KindName.Movie,
  KindName.Music,
  KindName.Television,
  KindName.BingePass,
]

const FETCH_KINDS_QUERY = gql`
  query FetchKinds {
    kinds {
      enabled
      id
      name
      plural
      singular
    }
  }
`

export function getSlugForKind(kind: Kind): string {
  if (kind.name === KindName.BingePass) {
    return 'binge'
  } else {
    return kind.name.toLowerCase()
  }
}

export const useKinds = create(
  (set: SetState<KindState>, get: GetState<KindState>): KindState => ({
    enabledKinds: [],
    fetch: async () => {
      set({ isLoadingKinds: true })

      const { data } = await client.query({
        query: FETCH_KINDS_QUERY,
        fetchPolicy: 'cache-first',
      })

      if (data?.kinds) {
        const orderedKinds = ORDERED_KINDS.map((kindName: string) =>
          data?.kinds.find((kind: Kind) => kind.name === kindName),
        )
          .filter((kind) => !!kind)
          .map((k) => ({ ...k, slug: getSlugForKind(k) }))

        const enabledKinds = orderedKinds.filter((kind) => kind.enabled)

        get().save(enabledKinds, orderedKinds)
      }

      set({ isLoadingKinds: false })
    },
    getKindById: (kindId: string) => {
      const orderedKinds = get().orderedKinds

      if (!orderedKinds || !orderedKinds.length) return null

      return orderedKinds.find(
        (kind: Kind) => parseInt(kind.id) === parseInt(kindId),
      )
    },
    getKindByName: (kindName: KindName) => {
      const orderedKinds = get().orderedKinds

      if (!orderedKinds || !orderedKinds.length) return null

      return orderedKinds.find((kind: Kind) => kind.name === kindName)
    },
    getKindsEnabledByName: () => {
      const enabledKinds = get().enabledKinds

      return keyBy(enabledKinds, (kind) => kind.name.toLowerCase() as string)
    },
    getKindsByName: () => {
      const orderedKinds = get().orderedKinds

      return keyBy(orderedKinds, (kind) => kind.name.toLowerCase() as string)
    },
    isLoadingKinds: false,
    orderedKinds: [],
    refetch: () => {
      return get().fetch()
    },
    save: (enabledKinds: Kind[], orderedKinds: Kind[]) => {
      if (
        !isEqual(get().enabledKinds, enabledKinds) &&
        !isEqual(get().orderedKinds, orderedKinds)
      ) {
        set({ enabledKinds, orderedKinds })
      }
    },
  }),
)
