import { gql } from '@apollo/client'
import { client } from 'graphql/client'

export const TITLE_OVERLAY_FRAGMENT = gql`
  fragment TitleOverlayFragment on TitleOverlay {
    name
    backColor
    foreColor
  }
`

export const TITLE_LIST_ITEM_FRAGMENT = gql`
  fragment TitleListItemFragment on Title {
    id
    artKey
    issueNumberDescription
    kind {
      name
    }
    overlay {
      ...TitleOverlayFragment
    }
    parentalAdvisory
    primaryArtist {
      name
    }
    releaseDate
    title
    titleId
    status
    licenseType
    __typename
  }
  ${TITLE_OVERLAY_FRAGMENT}
`

export const BORROWED_ITEM_FRAGMENT = gql`
  fragment BorrowedItemFragment on Title {
    id
    artKey
    bingePassType
    issueNumberDescription
    kind {
      name
    }
    parentalAdvisory
    primaryArtist {
      name
    }
    circulation {
      id
    }
    title
    titleId
    status
    licenseType
    playbackPosition {
      percentComplete
    }
    tracks {
      id
      contentId
      segmentNumber
      name
      mediaKey
      seconds
    }
    externalCatalogUrl
    __typename
  }
`

const CONTENT_FRAGMENT = gql`
  fragment ContentFragment on Content {
    artKey
    issueNumberDescription
    contentId
    episodeTitle
    kind {
      name
      id
    }
    parentalAdvisory
    primaryArtist {
      name
    }
    releaseDate
    title
    titleId
    titleRating {
      totalCount
      weightedAverage
    }
    patronTitleRating {
      stars
    }
    __typename
  }
`

export const CIRCULATION_FRAGMENT = gql`
  fragment CirculationFragment on Circulation {
    id
    episode {
      title
    }
    title {
      ...TitleListItemFragment
    }
    licenseType
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
`

export const PROMO_FRAGMENT = gql`
  fragment PromoFragment on Promotion {
    image
    overlayColor
    overlayText
    target
    targetId
    marquee
  }
`

export const CATEGORIES_QUERY = gql`
  query CategoryList($kindId: Int!) {
    categories(kindId: $kindId) {
      top {
        id
        name
        type
      }
      general {
        id
        name
        type
      }
    }
  }
`

export const GENRE_QUERY = gql`
  query Genre($genreId: ID!) {
    genre(id: $genreId) {
      id
      name
      isParent
      children {
        name
        id
      }
      kind {
        name
        plural
      }
      ancestors {
        name
        id
      }
    }
  }
`

export const TRENDING_SEARCH_QUERY = gql`
  query TrendingSearch($criteria: SearchCriteria!) {
    search(criteria: $criteria, from: 0, size: 4) {
      hits {
        ...TitleListItemFragment
      }
    }
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
`

export const COMIC_SERIES_TOP_TITLES = gql`
  query ComicBrowseTopSeriesTitles(
    $seriesId: ID
    $audience: SearchAudience
    $pagination: PaginationInput
  ) {
    recent: search(
      criteria: {
        seriesId: $seriesId
        audience: $audience
        pagination: $pagination
      }
      sort: DISPLAY_DATE
    ) {
      found
      hits {
        ...TitleListItemFragment
      }
    }
    ce: search(
      criteria: {
        seriesId: $seriesId
        comicType: CE
        audience: $audience
        pagination: $pagination
      }
    ) {
      found
      hits {
        ...TitleListItemFragment
      }
    }
    special: search(
      criteria: {
        seriesId: $seriesId
        comicType: SPECIAL
        audience: $audience
        pagination: $pagination
      }
      sort: A_Z
    ) {
      found
      hits {
        ...TitleListItemFragment
      }
    }
    issue: search(
      criteria: {
        seriesId: $seriesId
        comicType: ISSUE
        audience: $audience
        pagination: $pagination
      }
    ) {
      found
      hits {
        ...TitleListItemFragment
      }
    }
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
`

export const COMIC_PUBLISHER_RECENT = gql`
  query ComicBrowseTopSeriesTitles($criteria: SearchCriteria!) {
    search(criteria: $criteria) {
      hits {
        ...TitleListItemFragment
      }
    }
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
`

export const SUGGESTED_TITLES = gql`
  query SuggestTitles($criteria: SearchCriteria!, $size: Int!) {
    search(criteria: $criteria, from: 0, size: $size) {
      hits {
        ...TitleListItemFragment
      }
    }
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
`

export const FAVORITES = gql`
  {
    favorites {
      artists {
        id
        name
        artKey
      }
      series {
        kind {
          name
        }
        id
        name
        artKey
      }
      audiobooks {
        ...TitleListItemFragment
      }
      comics {
        ...TitleListItemFragment
      }
      ebooks {
        ...TitleListItemFragment
      }
      movies {
        ...TitleListItemFragment
      }
      music {
        ...TitleListItemFragment
      }
      television {
        ...TitleListItemFragment
      }
    }
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
`

export const SEARCH_SUGGESTIONS = gql`
  query SearchSuggestions($prefix: String!, $audience: SearchAudience) {
    searchSuggestions(prefix: $prefix, audience: $audience) {
      artists {
        id
        text
      }
      titles {
        id
        text
      }
      series {
        id
        text
      }
    }
  }
`

export const POST_PLAY = gql`
  query PostPlay($circId: ID!, $audience: PostPlayAudience) {
    postPlay(circulationId: $circId, criteria: { audience: $audience }) {
      current {
        ...ContentFragment
      }
      suggestedNext {
        content {
          ...ContentFragment
        }
        licenseType
        status
        type
      }
      suggestReturn
      suggestedTitles {
        ...TitleListItemFragment
      }
    }
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
  ${CONTENT_FRAGMENT}
`

export const FAVORITE_ITEMS_DETAIL = gql`
  query FavoriteItemsDetail($criteria: FavoritesCriteria!) {
    favoriteItems(criteria: $criteria) {
      results {
        type
        title {
          ...TitleListItemFragment
        }
        series {
          kind {
            name
          }
          id
          name
          artKey
        }
        artist {
          id
          name
          artKey
        }
      }
      pagination {
        hasNext
        hasPrevious
      }
    }
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
`

export const PATRON_HISTORY = gql`
  query PatronHistory(
    $historyAudience: HistoryAudience
    $page: ConstrainNumberMIN1
    $pageSize: ConstrainNumberMIN1
  ) {
    history(
      criteria: {
        pagination: { page: $page, pageSize: $pageSize }
        audience: $historyAudience
      }
    ) {
      id
      borrowedDate
      episode {
        title
      }
      title {
        ...TitleListItemFragment
      }
      licenseType
    }
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
`

export const COLLECTIONS_LIST_QUERY = gql`
  query CollectionsListQuery($kindId: ID!, $audience: CollectionsAudience) {
    library: collections(
      criteria: {
        kindId: $kindId
        audience: $audience
        type: PRIVATE
        pagination: { page: 1, pageSize: 10 }
      }
    ) {
      id
      name
    }

    featured: collections(
      criteria: {
        kindId: $kindId
        audience: $audience
        type: FEATURED
        pagination: { page: 1, pageSize: 10 }
      }
    ) {
      id
      name
    }

    all: collections(
      criteria: {
        kindId: $kindId
        audience: $audience
        type: PUBLIC
        pagination: { page: 1, pageSize: 43 }
      }
    ) {
      id
      name
    }
  }
`

export const COLLECTIONS_DETAIL_QUERY = gql`
  query CollectionsDetailQuery(
    $kindId: ID!
    $audience: CollectionsAudience
    $type: CollectionsType
  ) {
    collections(
      criteria: { kindId: $kindId, audience: $audience, type: $type }
    ) {
      id
      name
    }
  }
`

export const GENRES_LIST_QUERY = gql`
  query GenresListQuery($kindId: ID!, $audience: GenresAudience) {
    genres(
      criteria: { kindId: $kindId, type: TOP_LEVEL, audience: $audience }
    ) {
      isParent
      id
      name
    }
  }
`

export const SERIES_QUERY = gql`
  query seriesQuery($id: ID!) {
    series(id: $id) {
      id
      name
      isFavorited
      kind {
        name
        id
      }
    }
    __typename
  }
`

export const GENRES_DETAIL_QUERY = gql`
  query GenresDetailQuery($genreId: ID!, $audience: GenresAudience) {
    genre(id: $genreId, audience: $audience) {
      ancestors {
        name
        id
      }
      children {
        name
        id
        isParent
      }
      id
      name
    }
  }
`

export const CATEGORY_SEARCH = gql`
  query CategorySearch(
    $q: String
    $kindId: ID!
    $genreAudience: GenresAudience
    $collectionAudience: CollectionsAudience
    $bisacAudience: BisacAudience
  ) {
    genres(criteria: { kindId: $kindId, audience: $genreAudience, q: $q }) {
      isParent
      id
      name
      ancestors {
        name
      }
    }
    collections(
      criteria: { kindId: $kindId, audience: $collectionAudience, q: $q }
    ) {
      name
      id
    }
    bisacs(criteria: { kindId: $kindId, audience: $bisacAudience, q: $q }) {
      id
      name
      isParent
      ancestors {
        name
      }
    }
  }
`

export const PUBLISHER_LIST = gql`
  query fetchPublishers($kindId: ID!, $sort: PublisherSort) {
    publishers(
      criteria: {
        kindId: $kindId
        pagination: { page: 1, pageSize: 100 }
        sort: $sort
      }
    ) {
      id
      name
      imageFileName
    }
  }
`

export const PATRON_QUERY = gql`
  query PatronQuery {
    patron {
      email
      hideHistory
      hooplaUserId
      id
      library {
        id
        name
        splashConfig {
          backgroundColor
          displayText
          imageName
          seconds
          textColor
        }
        settings {
          defaultAvailabilityFilter
          enableAutoLend
          enableBorrowEmails
          enableEST
          enableGeneralEmails
          enableHolds
          enablePPU
          enableTitleRequests
          enabled
          libraryCardUrl
          maxConcurrentEstCirculations
          maxPPUCirculationsPerMonth
        }
      }
      libraryCard
      provisional
    }
  }
`

export const updateTitleStatus = (id, status) => {
  client.writeFragment({
    id: `Title:${id}`,
    fragment: gql`
      fragment TitleFragment on Title {
        status
        __typename
      }
    `,
    data: {
      status,
      __typename: 'Title',
    },
  })
}

export const changeSeriesFavorite = (seriesId, isFavorited) => {
  client.writeFragment({
    id: `Series:${seriesId}`,
    fragment: gql`
      fragment SeriesFragment on Series {
        isFavorited
      }
    `,
    data: {
      isFavorited,
    },
  })
}

export const updateTitleRating = (id, stars, totalCount) => {
  client.writeFragment({
    id: `Title:${id}`,
    fragment: gql`
      fragment TitleFragment on Title {
        patronRating
        titleRating
        __typename
      }
    `,
    data: {
      patronRating: {
        stars,
      },
      titleRating: {
        totalCount,
      },
      __typename: 'Title',
    },
  })
}

export const updateTitleFavorite = (id, favorite) => {
  client.writeFragment({
    id: `Title:${id}`,
    fragment: gql`
      fragment TitleFragment on Title {
        favorite
        __typename
      }
    `,
    data: {
      favorite,
      __typename: 'Title',
    },
  })
}

export const JUST_FOR_YOU_RECOMMENDATIONS_QUERY = gql`
  query dsgFlexRecommendations($criteria: DSGFlexRecommendationsInput!) {
    dsgFlexRecommendations(criteria: $criteria) {
      algorithm
      collectionTitle
      found
      hits {
        ...TitleListItemFragment
      }
    }
  }
  ${TITLE_LIST_ITEM_FRAGMENT}
`
