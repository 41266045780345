import { getDeviceId } from 'util/user'
import { KindName } from 'common'
import { useCallback } from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/browser'
import config from 'config'
import { useUser } from 'state/useUser'
import UAParser from 'ua-parser-js'
import create, { SetState } from 'zustand'

// Enums
export enum EventCategory {
  carousel = 'CAROUSEL',
  click = 'CLICK',
  conversion = 'CONVERSION',
  grid = 'GRID',
  hootie = 'HOOTIE',
  registration = 'REGISTRATION',
  search = 'SEARCH',
  titleDetails = 'TITLE_DETAILS',
}

export enum EventInteraction {
  appEvent = 'APP_EVENT',
  appStart = 'APP_START',
  event = 'EVENT',
  pageLoad = 'PAGE_LOAD',
}

// Types
export type Item = {
  type: string
  id: string
  kindName?: KindName
  licenseType?: string
  status?: string
}

type View = {
  name: string
  id?: string
}

export type PageLoadDetails = {
  label?: string
  category?: EventCategory
}

type BaseEvent = {
  category?: EventCategory | null
  interactionType?: EventInteraction
  label?: string
}

type RegistrationEvent = BaseEvent & {
  value?:
    | string
    | {
        email?: string
        address?: string
        zip?: string
        libraryId?: Library['id'] | string
      }
}

type SearchEvent = BaseEvent & {
  eventSource?: string
  value: string
}

type PageLoadEvent =
  | (BaseEvent & {
      licenseType: string
      status: string
      value: string | null
    })
  | (BaseEvent & {
      view: string
      value?: string
    })

type PDPEvent = BaseEvent & {
  value?: string
}

type CarouselEvent = BaseEvent & {
  value: string
  eventSource?: string
  ordinal?: number
  sectionHeader?: string
  view?: string
}

type ClickEvent = BaseEvent & {
  value?: {
    type?: 'title' | 'artist' | 'series' | 'circulation' | 'content'
    id?: number | string
    favorite?: 'add' | 'remove'
    rating?: number
    role?: string
  }
}

type EventData =
  | BaseEvent
  | SearchEvent
  | PageLoadEvent
  | PDPEvent
  | CarouselEvent
  | RegistrationEvent
  | ClickEvent

type UseAnalyticsState = {
  currentPageLoaded?: string
  setCurrentPageLoaded: (currentPageLoaded: string) => void
}

type useAnalyticsResponse = {
  currentPage?: string
  sendEvent: (data: EventData) => void
  carouselLoaded: (
    items: Item[],
    name: string,
    ordinal?: number,
    algorithm?: string,
  ) => void
  carouselItemClicked: (
    item: Item,
    carouselName: string,
    ordinal?: number,
    algorithm?: string,
  ) => void
  sendAuthenticationEvent: (label: string) => void
  sendRegistrationIntroEvent: (data: RegistrationEvent) => void
  sendRegistrationEvent: (data: RegistrationEvent) => void
  setPageLoaded: (pageLoaded: View, pageLoadDetails?: PageLoadDetails) => void
  sendClickEvent: (props: ClickEvent) => void
}

// Constants
export const EVENT_ERROR = 'ERROR'

const parser = new UAParser()

// State
const useAnalyticsState = create<UseAnalyticsState>(
  (set: SetState<UseAnalyticsState>) => ({
    currentPageLoaded: undefined,
    setCurrentPageLoaded: (currentPageLoaded) => set({ currentPageLoaded }),
  }),
)

// Hook
export default function useAnalytics(): useAnalyticsResponse {
  const user = useUser((state) => state.user)
  const patron = user?.patrons?.[0]
  const libraryId = patron?.libraryId?.toString()
  const patronId = patron?.id?.toString()
  const { currentPageLoaded, setCurrentPageLoaded } = useAnalyticsState()

  const sendEvent = useCallback(
    (data: EventData) => {
      const deviceId = getDeviceId()

      const eventData = {
        app: 'WWW',
        appVersion: process.env.REACT_APP_VERSION,
        deviceId,
        deviceModel: parser.getBrowser().name,
        deviceVersion: parser.getBrowser().version,
        libraryId: libraryId ?? undefined,
        os: parser.getOS().name,
        osVersion: parser.getOS().version,
        patronId: patronId ?? undefined,
        timestamp: new Date().getTime(),
        url: window.location.href,
        view: currentPageLoaded,
        ...data,
      }

      analyticsClient.post('/patron/event', eventData)
    },
    [currentPageLoaded, libraryId, patronId],
  )

  function sendAuthenticationEvent(label: string) {
    sendEvent({
      interactionType: EventInteraction.event,
      category: EventCategory.click,
      label,
    })
  }

  const sendRegistrationIntroEvent = ({
    interactionType,
    label,
    value,
  }: {
    interactionType?: EventInteraction
    label?: string
    value?: RegistrationEvent['value']
  }) => {
    return sendEvent({
      category: EventCategory.registration,
      interactionType: interactionType ?? EventInteraction.event,
      label,
      value: JSON.stringify(value),
    })
  }

  const sendRegistrationEvent = ({
    interactionType,
    label,
    value,
  }: {
    interactionType?: EventInteraction
    label?: string
    value?: RegistrationEvent['value']
  }) => {
    return sendEvent({
      category: EventCategory.registration,
      interactionType: interactionType ?? EventInteraction.event,
      label,
      value: JSON.stringify(value),
    })
  }

  function carouselLoaded(
    items: Item[],
    name: string,
    ordinal?: number,
    algorithm?: string,
  ) {
    if (!items.length) {
      return
    }

    if (ordinal === undefined) {
      return Bugsnag.leaveBreadcrumb('no ordinal provided on carouselLoaded', {
        items,
        name,
      })
    }

    sendEvent({
      category: EventCategory.carousel,
      eventSource: algorithm,
      interactionType: EventInteraction.appEvent,
      label: 'carousel_loaded',
      ordinal,
      sectionHeader: name,
      value: JSON.stringify(items),
    })
  }

  function carouselItemClicked(
    item: Item,
    carouselName: string,
    ordinal?: number,
    algorithm?: string,
  ) {
    if (!ordinal) {
      Bugsnag.leaveBreadcrumb('no ordinal provided on carouselItemClicked', {
        item,
        carouselName,
      })
    }

    sendEvent({
      category: EventCategory.carousel,
      eventSource: algorithm,
      interactionType: EventInteraction.event,
      label: 'carousel_item_clicked',
      ordinal,
      sectionHeader: carouselName,
      value: JSON.stringify(item),
    })
  }

  function setPageLoaded(pageLoaded: View, pageLoadDetails?: PageLoadDetails) {
    // ensures that the page being loaded and value are not sent in an event twice
    // also ensures that a page, such as search, can fire multiple times if the value is updated
    const pageLoadedJSON = JSON.stringify(pageLoaded)
    if (pageLoadedJSON === currentPageLoaded) {
      return
    }

    setCurrentPageLoaded(pageLoadedJSON)

    return sendEvent({
      label: pageLoadDetails?.label,
      category: pageLoadDetails?.category,
      interactionType: EventInteraction.pageLoad,
      view: pageLoadedJSON,
    })
  }

  function sendClickEvent({ label, value }: ClickEvent) {
    return sendEvent({
      category: EventCategory.click,
      interactionType: EventInteraction.event,
      label: label,
      value: JSON.stringify(value),
    })
  }

  return {
    currentPage: currentPageLoaded,
    sendEvent,
    setPageLoaded,
    sendClickEvent,
    carouselLoaded,
    carouselItemClicked,
    sendRegistrationIntroEvent,
    sendRegistrationEvent,
    sendAuthenticationEvent,
  }
}

const analyticsClient = axios.create({
  baseURL: config.analyticsApi,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})
