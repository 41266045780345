import { defaultIconProps } from 'components/common/icons'

export const Hootie = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <img
    className={className}
    width={size}
    height={size}
    alt="hootieIcon"
    src="https://web.hoopladigital.com/hootie/hootieicon.png"
  />
)
