// REACT IMPORTS
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

// ABSOLUTE IMPORTS
import { api as userApi } from 'api/userApi'
import Checkbox from 'components/forms/Checkbox'
import TextField from 'components/forms/TextField'
import useAnalytics, { EventCategory } from 'components/hooks/useAnalytics'
import { Stepper } from 'components/registration/ui'
import { Loading } from 'components/ui'
import { MessageLevel, useNotification } from 'state/useNotification'
import { useRegistration, useRegistrationState } from 'state/useRegistration'
import { PrimaryButton } from 'ui/buttons/PrimaryButton'

const messages: IntlMessage = defineMessages({
  email: {
    id: 'registration.account.libraryCard',
    defaultMessage: 'Email',
  },
  emailRequired: {
    id: 'registration.account.email.required',
    defaultMessage: 'Email is required',
  },
  emailInvalid: {
    id: 'setting.email.invalid',
    defaultMessage: 'Your email appears to be invalid.',
  },
  password: {
    id: 'registration.account.password',
    defaultMessage: 'Password',
  },
  passwordRequired: {
    id: 'registration.account.password.required',
    defaultMessage: 'Password is required',
  },
  passwordLength: {
    id: 'registration.account.password.length',
    defaultMessage: 'Your password must be between 8 and 40 characters',
  },
  registrationError: {
    id: 'registration.account.registrationError',
    defaultMessage:
      'There was an error creating your account, please try again.',
  },
  registrationSuccessHeader: {
    id: 'registration.success.header',
    defaultMessage: 'Login Success',
  },
  registrationSuccessBody: {
    id: 'registration.success.body',
    defaultMessage:
      'Your email and password match information we have for an existing hoopla user. You have been logged in to that account.',
  },
})

export default function SetupAccount({
  onBack = () => {},
  onNext = () => {},
}: BaseRegistrationProps) {
  // HOOKS
  const { sendRegistrationEvent, setPageLoaded } = useAnalytics()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: '',
      emailConsent: false,
      password: '',
      notificationConsent: false,
    },
    mode: 'onTouched',
  })
  const { formatMessage } = useIntl()
  const location: any = useLocation()
  const navigate = useNavigate()
  const { setNotification } = useNotification()
  const { loginUser } = useRegistration()

  // STATE
  const formData = useRegistrationState((state) => state.formData)
  const library = useRegistrationState((state) => state.formData.library)
  const [error, setError] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)

  setPageLoaded(
    { name: 'REGISTRATION_ACCOUNT_INFO' },
    {
      category: EventCategory.registration,
      label: 'almost_done',
    },
  )

  async function onSubmit({
    email,
    emailConsent,
    password,
    notificationConsent,
  }: RegistrationFormData) {
    try {
      const libraryId = library?.id || -1
      const emailConsentFinal = library?.country === 'US' || emailConsent
      const notificationConsentFinal =
        library?.country === 'US' || notificationConsent
      const res = await userApi.registerUser({
        ...formData,
        email,
        password,
        emailConsent: emailConsentFinal,
        libraryId,
        notificationConsent: notificationConsentFinal,
      })
      const registrationConfirmation = res.data

      setError('')
      sendRegistrationEvent({ label: 'complete_signup' })

      const { token } = registrationConfirmation
      await loginUser(token)
      onNext({
        email,
        emailConsent: emailConsentFinal,
        password,
        registrationConfirmation,
        notificationConsent: notificationConsentFinal,
      })
    } catch (err: any) {
      const errorMessage =
        err?.response?.data?.message ??
        formatMessage(messages.registrationError)

      if (err.response?.data?.reason === 'PATRON_EXISTS_ERROR') {
        const { authToken } = err?.response?.data
        if (authToken) {
          await loginUser(authToken)

          setNotification({
            messageLevel: MessageLevel.success,
            messageHeader: formatMessage(messages.registrationSuccessHeader),
            messageBody: formatMessage(messages.registrationSuccessBody),
          })

          return navigate(location?.state?.prevLocation ?? '/my/hoopla')
        }
      }

      setError(errorMessage)
    }
  }

  return (
    <div className="flex h-full flex-col">
      <Stepper onBack={() => onBack()} />
      <form
        className="flex h-full w-full flex-col"
        onSubmit={handleSubmit(onSubmit)}>
        <h1 className="mb-2 text-center text-3xl font-bold">
          <FormattedMessage
            id="registration.account.almost"
            defaultMessage="Almost Done!"
          />
        </h1>
        <div className="flex flex-col items-center space-y-1">
          <p className="text-left text-gray-500">
            <FormattedMessage
              id="registration.account.emailConsent.info"
              defaultMessage="We promise not to flood your inbox—we will only use your email address for essential communications."
            />
          </p>
        </div>
        <div className="my-4">
          <TextField
            autoComplete="email"
            disabled={isSubmitting}
            id="email"
            error={errors?.email?.message}
            label={formatMessage(messages.email)}
            maxLength={75}
            placeHolder={formatMessage(messages.email)}
            {...register('email', {
              required: formatMessage(messages.emailRequired),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: formatMessage(messages.emailInvalid),
              },
            })}
          />

          <TextField
            autoComplete="new-password"
            disabled={isSubmitting}
            id="password"
            error={errors?.password?.message}
            inputType={showPassword ? 'text' : 'password'}
            label={formatMessage(messages.password)}
            maxLength={40}
            placeHolder={formatMessage(messages.password)}
            {...register('password', {
              required: formatMessage(messages.passwordRequired),
              minLength: {
                value: 8,
                message: formatMessage(messages.passwordLength),
              },
              maxLength: {
                value: 40,
                message: formatMessage(messages.passwordLength),
              },
            })}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
          <p className="-mt-4 text-gray-400">
            <FormattedMessage
              id="registration.account.password.info"
              defaultMessage="Must be 8 to 40 characters long"
            />
          </p>
        </div>

        {library?.country !== 'US' && (
          <>
            <Checkbox
              {...register('emailConsent')}
              id="emailConsent"
              label={
                <FormattedMessage
                  id="registration.account.emailConsent"
                  defaultMessage="Please email me about new hoopla content and features"
                />
              }
            />
            <Checkbox
              {...register('notificationConsent')}
              id="notificationConsent"
              label={
                <FormattedMessage
                  id="registration.account.notificationConsent"
                  defaultMessage="Please send me push notifications about new hoopla content and features"
                />
              }
            />
          </>
        )}

        {error && <p className="text-sm text-red-600">{error}</p>}

        <PrimaryButton
          className="mx-auto mt-auto h-12 w-11/12"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}>
          {isSubmitting ? (
            <Loading secondary width={30} height={30} delay={0} />
          ) : (
            <FormattedMessage
              id="registration.button.complete"
              defaultMessage="Complete Registration"
            />
          )}
        </PrimaryButton>
      </form>
    </div>
  )
}
